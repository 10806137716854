const CryptoJS = require("crypto-js")

let key = CryptoJS.enc.Base64.parse(process.env.VUE_APP_BANK_DETAIL_SECRET_KEY)
let iv = CryptoJS.enc.Base64.parse(process.env.VUE_APP_BANK_DETAIL_SECRET_IV)

export const encrypt = (value) => {

  // old method 
  // return CryptoJS.AES.encrypt(value, secretKey).toString()

  const encrypted = CryptoJS.AES.encrypt(value, key, { 
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })

  const output = CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
  return output
}

export const decrypt = (value) => {

  // old method
  // var bytes  = CryptoJS.AES.decrypt(value, secretKey)
  // return bytes.toString(CryptoJS.enc.Utf8)

  let ciphertext = CryptoJS.enc.Base64.parse(value).words
  ciphertext = CryptoJS.lib.WordArray.create(ciphertext)
  ciphertext = CryptoJS.enc.Base64.stringify(ciphertext)

  const decrypted = CryptoJS.AES.decrypt(ciphertext, key, { iv })
  const clearText = CryptoJS.enc.Utf8.stringify(decrypted);

  return clearText
}
